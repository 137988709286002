import { ChevronRight } from '@grupo-sbf/motriz-icons/centauro';
import styled, { css } from 'styled-components';

export const ContainerBreadcrumb = styled.nav`
  ul {
    display: flex;
    list-style: none;

    ${({ theme }) => css`
      padding: ${theme.spacing[0]};
    `}
  }
  li {
    display: flex;
    align-items: center;
  }
  ul li a {
    text-decoration: none;

    ${({ theme }) => css`
      color: ${theme.color.neutral[500]};
    `}
  }
`;

export const CurrentBreadcrumb = styled.a`
  ${({ theme }) => css`
    font-weight: ${theme.fontWeight.bold} !important;
  `}
`;

export const BreadcrumbIcon = styled(ChevronRight)`
  ${({ theme }) => css`
    color: ${theme.color.neutral[500]};
  `}
`;
