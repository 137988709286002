import { sendClickEvent } from '@/common/utils/tracking/trackingEvents';

export const sendOpenTipsModalEvent = (location: string) => {
  sendClickEvent({
    component: 'subheader',
    micro_component: 'button',
    item_id: `open_tips_modal_button_${location}`,
    content_type: 'open_tips_modal_button_click',
  });
};
