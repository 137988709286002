import {
  CheckCircle,
  AlertCircle as ErrorOutlineIcon,
} from '@grupo-sbf/motriz-icons/centauro';
import { centauro } from '@grupo-sbf/motriz-tokens/themes';
import styled from 'styled-components';

export const Wrapper = styled.div<{ success: boolean }>`
  display: flex;
  flex: 0 0 409px;
  text-align: left;
  max-width: 409px;
  background-color: ${centauro.color.neutral[100]};
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  padding-right: 16px;
  &:before {
    top: 0;
    content: "''";
    background: ${({ success }) =>
      success ? centauro.color.success[200] : centauro.color.brand.primary};
    width: 13px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
`;

export const StyledErrorIcon = styled(ErrorOutlineIcon)`
  margin: 16px 16px 0px 12px;
  color: ${centauro.color.brand.primary};
  width: 32px;
  height: 32px;
`;

export const StyledSuccessIcon = styled(CheckCircle)`
  margin: 16px 16px 0px 12px;
  color: ${centauro.color.success[200]};
  width: 32px;
  height: 32px;
`;

export const StyledDescription = styled.p`
  padding-bottom: 12px;
`;

export const StyledTitle = styled.h3`
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.375rem;
  color: ${centauro.color.neutral[700]};
  margin: 16px 0 0 0;
`;
